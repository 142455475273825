<template>
  <div class="info-notification">
    <h4 class="info-notification__title">
      <svg-icon name="info" class="info-notification__ico" />
      {{ $t('notification.appeal_to_user') }}
    </h4>
    {{ $t('notification.add_key.txt_msg_0')
    }}<span class="info-notification__link" @click="goToHandler">{{
      $t('notification.add_key.link')
    }}</span
    >{{ $t('notification.add_key.txt_msg_1') }}
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  methods: {
    goToHandler() {
      if (!!this.path?.length) {
        this.$router.push({ name: this.path });
      }
    },
  },
};
</script>
